/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Navigation from './Navigation';
import WorkingAtLaptop from 'src/images/laptop.inline.svg';
import Page from './Page';

const Grid = styled.div`
  display: grid;
  max-height: 70%;

  grid-template-columns: 1fr minmax(500px, 100vw) minmax(300px, 500px);
  grid-template-rows: 220px 260px minmax(0px, 250px) auto;
  grid-template-areas:
    'logo     pink-box        nav         '
    'title    pink-box        illustration'
    '.        pink-box        purple-box  '
    '.        call-to-action  purple-box  ';

  grid-area: 1/2;
  margin: 0 125px;

  @media (max-width: 1200px) {
    margin: 0 50px;
    grid-template-columns: 1fr minmax(320px, 100vw) minmax(300px, 500px);
  }

  @media (max-width: 840px) {
    margin: 0 20px;
    grid-area: 1/1;
    grid-template:
      'logo' auto
      'nav' auto
      'title' auto
      'call-to-action' auto
      'illustration' auto
      / 1fr;
  }
`;

const Logo = styled.h4`
  grid-area: logo;
  display: flex;
  align-items: center;
  padding-right: 20px;

  @media (max-width: 840px) {
    margin-top: 40px;
    max-width: 18px;
    border-top: 10px solid #ffbed6;
  }
`;

const Title = styled.div`
  grid-area: title;
  width: 2px;
  white-space: nowrap;
  overflow: visible;
  z-index: 2;

  @media (max-width: 840px) {
    width: 90%;
  }
`;

const CallToAction = styled.div`
  grid-area: call-to-action;

  p {
    color: #666666;
    font-size: 1.2rem;
    line-height: 1.5em;
    max-width: 350px;
    margin: 60px 0 0 0;
  }

  @media (max-width: 840px) {
    p {
      margin: 40px 0 0 0;
    }
  }
`;

const Button = styled.a`
  display: block;
  text-decoration: none;
  border-left: 5px solid #ffbed6;
  margin-top: 50px;
  padding: 15px 0 15px 20px;
  font-weight: 700;
  color: #333333;

  @media (max-width: 840px) {
    margin-top: 20px;
  }
`;

const PinkBox = styled.div`
  background: #ffbed6;
  grid-area: pink-box;

  @media (max-width: 840px) {
    display: none;
  }
`;

const SmallPinkBox = styled.div`
  position: relative;
  left: -500px;
  top: -220px;
  width: calc(500px - 75px);
  height: 240px;

  opacity: 30%;
  background: #ffbed6;

  @media (max-width: 840px) {
    display: none;
  }
`;

const PurpleBox = styled.div`
  background: #a09eff;
  grid-area: purple-box;

  @media (max-width: 840px) {
    display: none;
  }
`;

const NavContainer = styled.div`
  grid-area: nav;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 840px) {
    justify-content: flex-start;
  }
`;

const IllustrationContainer = styled.div`
  grid-area: illustration;
  width: 2px;
  overflow: visible;

  @media (max-width: 840px) {
    width: 100%;
  }
`;

const Illustration = styled(WorkingAtLaptop)`
  position: relative;
  top: 240px;
  right: 200px;

  @media (max-width: 1200px) {
    width: 50vw;
    top: 200px;
    right: 40px;
  }

  @media (max-width: 840px) {
    width: 100vw;
    top: -220px;
    right: -20vw;
    margin-bottom: -200px;
    z-index: -1;
    opacity: 0.2;
  }
`;

const Heading = styled.h1`
  width: 680px;
  white-space: break-spaces;

  @media (max-width: 1200px) {
    font-size: 3rem;
    width: 500px;
  }

  @media (max-width: 840px) {
    margin-top: 50px;
    width: 100%;
    font-size: 2.4rem;
    padding-right: 20px;
  }
`;

const Subheading = styled.h2`
  @media (max-width: 840px) {
    white-space: break-spaces;
    font-size: 1.4rem;
    width: 100%;
  }
`;

type Props = {
  home: Home;
  person: Person;
  scrollToMyWork: () => void;
  scrollToAbout: () => void;
  scrollToContact: () => void;
};

const Hero: React.FC<Props> = ({ home, person, ...functions }) => (
  <Page>
    <Grid>
      <Logo>{home.title}</Logo>
      <Title>
        <Heading>{home.heading}</Heading>
        <Subheading>{home.subheading}</Subheading>

        <SmallPinkBox />
      </Title>

      <CallToAction>
        <p>
          I'm a {person.title} from {person.location}.
        </p>
        <Button href="#my-work" onClick={functions.scrollToMyWork}>
          Check out my work
        </Button>
      </CallToAction>

      <PinkBox />
      <PurpleBox />
      <IllustrationContainer>
        <Illustration />
      </IllustrationContainer>

      <NavContainer>
        <Navigation css={{ float: 'right' }} {...functions} />
      </NavContainer>
    </Grid>
  </Page>
);

export default Hero;
