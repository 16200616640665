/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import WatermarkTitle from './WatermarkTitle';
import React, { RefObject, RefAttributes } from 'react';

const Grid = styled.div`
  display: grid;
  margin-top: -300px;
  min-height: 100vh;
  grid-template: 1fr / 1fr fit-content(1600px) 1fr;

  @media (max-width: 1200px) {
    margin-top: -300px;
  }

  @media (max-width: 840px) {
    margin-top: -400px;
    grid-area: 1/1;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template: 1fr / 2fr 3fr;
  margin: 0 125px;
  grid-area: 1/2;
  z-index: 5;

  @media (max-width: 1200px) {
    grid-template: 1fr / 2fr 2fr;
    margin: 0 50px;
  }

  @media (max-width: 840px) {
    grid-template: 1fr / 1fr;
    margin: 0 20px;
    grid-area: 1/2;
  }
`;

const Text = styled.div`
  margin-bottom: 200px;

  @media (max-width: 840px) {
    margin-bottom: 100px;
  }
`;

const Button = styled.button`
  padding: 15px 15px;
  border: 0;
  background: #8d2c4f;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  float: right;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background: #5e1830;
  }
`;

interface Props {
  success: boolean;
}

const Contact: React.FC<Props & RefAttributes<HTMLDivElement>> = React.forwardRef(({ success }, ref) => (
  <div
    ref={ref as RefObject<HTMLDivElement>}
    css={{
      background: '#F3F2F9',
      width: '100vw',
      height: 'auto',
      scrollSnapAlign: 'start',
    }}
  >
    <WatermarkTitle>Contact</WatermarkTitle>
    <Grid>
      <Wrapper>
        <span>
          <h3 css={{ width: '100%' }}>Contact</h3>
          <Text>
            <p css={{ marginBottom: '60px' }}>
              If you want to work with me or have any other question, feel free to use the form below or mail me at{' '}
              <a className="link" href="mailto:matt.aarnoutse@gmail.com">
                matt.aarnoutse@gmail.com
              </a>
              .
            </p>

            <form name="contact" data-netlify="true" action="/?success=true">
              <input type="hidden" name="form-name" value="contact" />

              <section>
                <label htmlFor="name">
                  Name
                  <input id="name" name="name" type="text" required />
                </label>
              </section>

              <section>
                <label htmlFor="mail">
                  Mail
                  <input id="mail" name="mail" type="email" required />
                </label>
              </section>

              <section>
                <label htmlFor="message">
                  Message
                  <textarea id="message" name="message" rows={20} required />
                </label>
              </section>

              <span css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                <span
                  css={{
                    visibility: success ? 'visible' : 'hidden',
                    color: '#666666',
                    paddingRight: '20px',
                    lineHeight: '1.4em',
                    fontSize: '0.8rem',
                  }}
                >
                  Thank you for contacting me. I will get back to you as soon as possible.
                </span>
                <Button type="submit">Hit me up!</Button>
              </span>
            </form>
          </Text>
        </span>
      </Wrapper>
    </Grid>
  </div>
));

export default Contact;
