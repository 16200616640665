/** @jsx jsx */
import { jsx } from '@emotion/core';

export default ({ children }) => (
  <div
    style={{
      margin: '0 auto',
      // scrollSnapType: 'y proximity',
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: '100vh',
    }}
  >
    {children}
  </div>
);
