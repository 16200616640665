/** @jsx jsx */
import { jsx } from '@emotion/core';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Layout from 'src/components/Layout';
import Hero from 'src/components/Hero';
import MyWork from 'src/components/MyWork';
import About from 'src/components/About';
import Contact from 'src/components/Contact';
import Footer from 'src/components/Footer';
import { useLocation } from '@reach/router';
import qs from 'query-string';
import { useRef, useEffect, MutableRefObject, useState } from 'react';

type RootProps = {
  data: { contentfulHome: Home; contentfulPerson: Person };
};

const RootIndex: React.FC<RootProps> = ({ data }) => {
  const siteTitle = get(data, 'contentfulHome.title');
  const location = useLocation();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const query = qs.parse(location.search);
    setSuccess(query.success === 'true');
  }, [location]);

  const myWork: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const about: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const contact: MutableRefObject<HTMLDivElement | null> = useRef(null);

  useEffect(() => {
    if (success && contact.current) {
      contact.current.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  }, [contact, success]);

  const scrollToMyWork = () => {
    if (myWork.current) {
      myWork.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  const scrollToAbout = () => {
    if (about.current) {
      about.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  const scrollToContact = () => {
    if (contact.current) {
      contact.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  return (
    <Layout>
      <Helmet title={siteTitle} />
      <Hero
        home={data.contentfulHome}
        person={data.contentfulPerson}
        scrollToMyWork={() => scrollToMyWork()}
        scrollToAbout={() => scrollToAbout()}
        scrollToContact={() => scrollToContact()}
      />

      <MyWork ref={myWork} />
      <About ref={about} />
      <Contact ref={contact} success={success} />

      <Footer />
    </Layout>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHome {
      id
      title
      subheading
      heading
    }
    contentfulPerson {
      id
      email
      facebook
      github
      name
      phone
      title
      location
      twitter
      shortBio {
        shortBio
      }
    }
  }
`;
