/** @jsx jsx */
import { css, jsx } from '@emotion/core';

type NavLinkProps = {
  to: string;
  first?: boolean;
  last?: boolean;
  onClick?: () => unknown;
};

const NavLink: React.FC<NavLinkProps> = ({ to, children, first, last, ...props }) => (
  <li
    css={css`
      list-style: 'none';
      display: block;
      margin: 0 20px;

      ${first ? 'margin-left: 0;' : ''}
      ${last ? 'margin-right: 0;' : ''}
    `}
  >
    <a href={to} css={{ textDecoration: 'none', color: '#333333', fontWeight: 600 }} {...props}>
      {children}
    </a>
  </li>
);

type NavigationProps = {
  scrollToMyWork: () => void;
  scrollToAbout: () => void;
  scrollToContact: () => void;
};

const Navigation: React.FC<NavigationProps> = ({ scrollToMyWork, scrollToAbout, scrollToContact }) => (
  <nav>
    <ul css={{ margin: 0, padding: 0, display: 'flex' }}>
      <NavLink to="#my-work" first onClick={scrollToMyWork}>
        My Work
      </NavLink>
      <NavLink to="#about" onClick={scrollToAbout}>
        About
      </NavLink>
      <NavLink to="#contact" last onClick={scrollToContact}>
        Contact
      </NavLink>
    </ul>
  </nav>
);

export default Navigation;
