/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState } from 'react';

interface Props {
  title: string;
  company: string;
  year: number;
  img?: string;
}

const Card: React.FC<Props> = ({ title, children, company, year, img }) => {
  const [isFallbackShown, showFallback] = useState(false);
  return (
    <div
      css={css`
        flex: 1 1 400px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        padding: 40px;
        max-width: 100%;
        // margin: 50px;

        p {
          margin-bottom: 80;
          line-height: 1.5em;
        }
      `}
    >
      <div>
        <h5>{title}</h5>
        <span
          css={css`
            display: block;
            font-weight: 600;
            margin-bottom: 5px;
          `}
        >
          {company}
        </span>
        <span
          css={css`
            display: block;
            font-weight: 400;
            color: #666;
            margin-bottom: 10px;
          `}
        >
          {year}
        </span>
      </div>
      {children}
      <div css={{ height: '270px', width: 'calc(100% + 80px)', margin: '0px -40px -40px -40px', overflow: 'hidden' }}>
        {!isFallbackShown && !!img && (
          <img
            css={{ width: '120%', height: '100%', objectFit: 'cover', objectPosition: '50% 0%' }}
            src={img}
            alt={title}
            onError={() => showFallback(true)}
          />
        )}
        {(isFallbackShown || !img) && <img css={{ width: '100%', height: '100%', objectFit: 'cover' }} src="error.svg" alt={title} />}
      </div>
    </div>
  );
};

export default Card;
