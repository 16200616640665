/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr fit-content(1600px) 1fr;
  grid-template-rows: 1fr;

  min-height: 100vh;
  scroll-snap-align: start;

  @media (max-width: 1200px) {
    grid-template-columns: auto;
  }
`;

const Page = ({ children, ...props }) => <Container {...props}>{children}</Container>;

export default Page;
