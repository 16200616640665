/** @jsx jsx */
import { jsx } from '@emotion/core';
import Container from './Container';

import './normalize.scss';
import './base.scss';

const Layout = ({ children }) => <Container>{children}</Container>;

export default Layout;
