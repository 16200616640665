/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import WatermarkTitle from './WatermarkTitle';
import Card from './Card';
import React, { RefAttributes } from 'react';

const Grid = styled.div`
  display: grid;
  margin-top: -300px;
  height: auto;
  grid-template: 1fr / 1fr fit-content(1600px) 1fr;

  @media (max-width: 1200px) {
    margin-top: -300px;
  }

  @media (max-width: 840px) {
    margin-top: -400px;
    grid-area: 1/1;
  }
`;

const Wrapper = styled.div`
  margin: 0 125px;
  grid-area: 1/2;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 0 50px;
  }

  @media (max-width: 840px) {
    margin: 0 20px;

    & > div {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
`;

const MyWork: React.FC<RefAttributes<HTMLDivElement>> = React.forwardRef((_, ref) => (
  <div
    ref={ref}
    css={{
      background: '#F3F2F9',
      width: '100vw',
      minHeight: '100vh',
      scrollSnapAlign: 'start',
    }}
  >
    <WatermarkTitle>My Work</WatermarkTitle>
    <Grid>
      <Wrapper>
        <h3 css={{ width: '100%', marginBottom: 0 }}>My Work</h3>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
            gridAutoRows: 'auto',
            gridGap: '4em',
            zIndex: 5,
            margin: '0 0 60px 0',
            paddingTop: '50px',
            scrollSnapAlign: 'start',
          }}
        >
          <Card title="Sense2Grow" company="Technolution" year={2018} img="s2g.png">
            <span>
              <p>Technolution wanted a redesign of a dashboard which monitors greenhouses.</p>
              <p>
                The challenges were to show the data in an effective but beautiful manner. This was realized with d3.js in an Angular
                environment.
              </p>
            </span>
          </Card>

          <Card title="Automatic Music Transcriber" company="Quintor" year={2019} img="amt.png">
            <span>
              <p>
                This project is particularly interesting from the development side. This site would enable the user to automatically
                transcribe any song into guitar tabs.
              </p>
              <p>As a guitar player myself this was quite exciting to be able to work on as my graduation project at Quintor.</p>
            </span>
          </Card>

          <Card title="Unknown" company="You?" year={new Date().getFullYear()}>
            <p>Your next big project.</p>
          </Card>
        </div>
      </Wrapper>
    </Grid>
  </div>
));

export default MyWork;
