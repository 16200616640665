/** @jsx jsx */
import { jsx } from '@emotion/core';

const Footer = () => (
  <div
    css={{
      display: 'flex',
      height: '200px',
      background: '#D7D7E5',
      color: '#5A5A7E',
      scrollSnapAlign: 'end',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '0 20px',
      lineHeight: '1.4em',
    }}
  >
    <span>Designed and developed by Matthias Aarnoutse © 2020</span>
  </div>
);

export default Footer;
