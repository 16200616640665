/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import WatermarkTitle from './WatermarkTitle';
import React, { RefAttributes } from 'react';

const Grid = styled.div`
  display: grid;
  margin-top: -300px;
  height: auto;
  grid-template: 1fr / 1fr fit-content(1600px) 1fr;

  @media (max-width: 1200px) {
    margin-top: -300px;
  }

  @media (max-width: 840px) {
    margin-top: -400px;
    grid-area: 1/1;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template: 1fr / 2fr 3fr;
  margin: 0 125px;
  grid-area: 1/2;

  @media (max-width: 1200px) {
    grid-template: 1fr / 2fr 2fr;
    margin: 0 50px;
  }

  @media (max-width: 840px) {
    grid-template: 1fr / 1fr;
    margin: 0 20px;
    grid-area: 1/2;
  }
`;

const Text = styled.div`
  margin-bottom: 200px;

  @media (max-width: 840px) {
    margin-bottom: 100px;
  }
`;

const About: React.FC<RefAttributes<HTMLDivElement>> = React.forwardRef((_, ref) => (
  <div
    ref={ref}
    css={{
      width: '100vw',
      height: 'auto',
      scrollSnapAlign: 'start',
    }}
  >
    <WatermarkTitle>About</WatermarkTitle>
    <Grid>
      <Wrapper>
        <span>
          <h3 css={{ width: '100%' }}>About</h3>
          <Text>
            <p>
              My name is Matthias Aarnoutse. I’m based in The Hague, The Netherlands and graduated in Software Engineering. My field of
              choice is front-end and I keep up to date with the latest technologies such as React and Vue.
            </p>

            <p>
              Currently I am following a master in Artificial Intelligence as it peaks my interest. This means that I am available for
              freelance projects on the side.
            </p>
          </Text>
        </span>
      </Wrapper>
    </Grid>
  </div>
));

export default About;
