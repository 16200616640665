/** @jsx jsx */
import { jsx } from '@emotion/core';

const WatermarkTitle = ({ children }) => (
  <div
    css={{
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
      width: '100vw',
    }}
  >
    <span
      css={{
        fontSize: '18rem',
        textAlign: 'right',
        whiteSpace: 'nowrap',
        fontWeight: 700,
        opacity: 0.02,
        color: 'black',
      }}
    >
      {children}
    </span>
  </div>
);

export default WatermarkTitle;
